<template>
    <div>
      <div
          class="subheader subheader-transparent"
          v-bind:class="subheaderClasses"
          id="kt_subheader"
      >
        <div
            class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
            v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
        >
          <div class="d-flex align-items-baseline mr-5">
            <!--begin::Page Title-->
            <h2 class="subheader-title my-2 mr-3">
              Call center
            </h2>
  
            <ul class="nav-items">
              <li :class="(currentRouteName == 'occ call center')?'active':''">
                <router-link to="/occ/call/center">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 25 268.09 263.49" style="enable-background:new 0 0 268.09 263.49;" xml:space="preserve">
                  <g>
                    <polygon points="226,164.22 242.04,146.28 136.81,53.8 30.64,146.28 50.46,164.22 136.81,87.77 	"
                             class="icon"/>
                    <path d="M136.81,99.1l-80.69,72.2v72.2H217.5v-69.84L136.81,99.1z M168.68,193.84h-23.9v23.7h-16.15v-23.7h-23.77v-16.16h23.77
                      v-23.83h16.15v23.83h23.9V193.84z" class="icon"/>
                  </g>
                  </svg>
                  <span>Outbound call   </span>
                </router-link>
              </li>
              <li :class="(currentRouteName == 'occ report')?'active':''">
                <router-link to="/occ/report">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 268.09 263.49" style="enable-background:new 0 0 268.09 263.49;" xml:space="preserve">
                  <g>
                    <path d="M53.93,243.49c0,0,162.24,0,162.24,0c8.48-3.12,15.9-10.69,18.84-19.17c7.16-20.61-7.21-42.46-23.18-53.82
                      c-10.78-7.66-23.65-11.98-36.92-11.76l-39.03,42.37l-42.93-42.38c0,0-51.85-4.45-61.33,46.84
                      C31.63,205.58,26.06,237.92,53.93,243.49z" class="icon"/>
                    <path d="M217.84,136.45l-24.53-56.87v-44.6l-59.27-20.63L76.79,35.54l0.56,44.04l-25.09,59.1c0,0-5.02,12.27,45.16,4.46
                      c0,0,42.37,34.57,74.71,0C172.13,143.14,221.19,150.39,217.84,136.45z M116.35,45.06h13.94V31.08h9.47v13.98h14.02v9.48h-14.02
                      v13.9h-9.47v-13.9h-13.94V45.06z M171.57,106.34c0,0-31.22,55.19-73.03,0V86.43h73.03V106.34z"
                          class="icon"/>
                  </g>
                  </svg>
                  <span>Inbound call</span>
                </router-link>
              </li>
              <li :class="(currentRouteName == 'occ patient report')?'active':''">
                <router-link to="/occ/patient/report">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 268.09 263.49" style="enable-background:new 0 0 268.09 263.49;" xml:space="preserve">
                  <g>
                    <path d="M186.74,48.96c-3.87-9.73-10.42-18.16-18.8-24.47l-29.89,24.47H186.74z" class="icon"/>
                    <path d="M155.3,17.19c-7.22-3.04-15.18-4.75-23.56-4.75c-25.12,0-46.51,15.18-55,36.52h39.76L155.3,17.19z"  class="icon"/>
                    <path d="M190.25,62.62h-68.88H99.81H73.22c-0.27,2.2-0.44,4.42-0.44,6.69c0,31.41,26.4,56.87,58.96,56.87
                      c32.56,0,58.96-25.46,58.96-56.87C190.7,67.04,190.52,64.82,190.25,62.62z"  class="icon"/>
                    <path d="M64.98,146.56v96.7c0.16,0,0.32,0,0.49,0c9.73,0,19.46,0,29.2,0c9.63,0,19.26,0,28.88,0L77.72,142.54
                      C73.19,143.41,68.93,144.75,64.98,146.56z"  class="icon"/>
                    <path d="M28.74,192.73c-4.33,15.03-4.86,35.26,9.24,45.39c3.78,2.72,7.76,4.03,11.88,4.65c0.3,0.04,0.6,0.06,0.9,0.09v-86.95
                      C40.21,165.31,32.86,178.42,28.74,192.73z"  class="icon"/>
                    <path d="M227.91,179.28c-1.61-3.33-4.13-6.44-6.43-9.31c-7.02-8.72-15.77-16.2-25.84-21.16c-6.04-2.98-12.51-4.97-19.17-5.96
                      c-9.73-1.45-16.55,0.68-25.37,4.17c-4.21,1.67-8.63,2.84-13.12,3.48c-7.84,1.11-16.11,0.53-23.68-1.81
                      c-6.46-2-12.18-6.52-19.06-7.06c-0.23-0.02-0.46-0.02-0.69-0.04l20.39,44.81h37c0,0,22.02,0.28,29.55,25.92
                      c4.17,14.19-1.66,24.53-7.7,30.94c9.6,0,19.21,0,28.81,0c6.19,0,12.66,1.34,17.88-2.31c6.9-4.83,11.95-12.04,14.15-20.17
                      C238.43,206.79,234.01,191.91,227.91,179.28z"  class="icon"/>
                    <path d="M169.23,222.9c-1.39-18.96-16.17-22.58-16.17-22.58h-31.78l19.53,42.93c4.7,0,9.41,0,14.11,0
                      C169.68,234.84,169.23,222.9,169.23,222.9z"  class="icon"/>
                  </g>
                  </svg>
                  <span>Call report</span>
                </router-link>
              </li>
            
        
            </ul>
          </div>
        </div>
  
        <div class="subheader-search-box">
            <form
                @submit.prevent="userSearch"
                class="w-100 d-flex align-items-center mt-3"
            >
                <!-- <input type="text" class="form-control border-redius-0" v-model="search_text"
                    placeholder="Search Users: Exp: name, phone number" aria-label="Recipient's username"
                    aria-describedby="button-addon2">
                <button class="btn btn-info border-redius-0" type="submit" id="button-addon2">Search</button> -->

                <div class="input-icons">
                <i class="fas fa-search icon"> </i>
                <input class="input-field" type="text" placeholder="Search"/>
                </div>
            </form>
        </div>
        <div class="mr-15">
            
          <b-dropdown
              size="lg"
              class="m-2 custom-dropdown rounded-circle btn-circle"
              toggle-class="rounded-circle"
              id="dropdown-right"
              right
              no-caret
          >
            <template #button-content class="custom-dropdown">
              <span class="name">
                {{ getSingleLettter(currentUser.fullname) }}
              </span>
            </template>
            <b-dropdown-item-button
                @click="onLogout"
                class="custom-dropdown-item"
            >
              <div class="d-flex align-items-center justify-content-center">
                <span class="pr-5 pt-2"><i class="fas fa-sign-out-alt"></i></span>
                <span>Logout</span>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </div>
      <div class="m-8"></div>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapActions} from "vuex";
  import axios from "axios";
  import {getSingleLettter} from "../../../healper/nameFormating";
  
  export default {
    name: "OccSubheader",
    data() {
      return {
        notifications: [],
        nameMenufulate: "",
        search_text: "",
      };
    },
    props: {
      breadcrumbs: Array,
      title: String,
    },
    mounted() {
      this.getUserPermissions();
    },
    computed: {
      ...mapGetters(["layoutConfig"]),
  
      /**
       * Check if subheader width is fluid
       */
      currentUser: {
        get() {
          return this.$store.state.auth.userData;
        },
      },
  
      currentRouteName() {
          return this.$route.name;
      },
  
      widthFluid() {
        return this.layoutConfig("subheader.width") === "fluid";
      },
  
      subheaderClasses() {
        const classes = [];
        const style = this.layoutConfig("subheader.style");
        if (style) {
          classes.push(style);
  
          if (style === "solid") {
            classes.push("bg-white");
          }
  
          if (this.layoutConfig("subheader.fixed")) {
            classes.push("border-top");
          }
  
          if (this.layoutConfig("subheader.clear")) {
            classes.push("mb-0");
          }
        }
        return classes.join(" ");
      },
    },
    methods: {
      ...mapActions("auth", ["getUserPermissions"]),
      getSingleLettter,
      onLogout() {
        this.$router.push("/login");
        localStorage.removeItem("api_token");
      },
  
      fullnameMenupulate() {
        var str = this.currentUser.fullname;
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        return matches.join(""); // JSON
      },
  
      getNotification() {
        axios
            .get(
                `${
                    process.env.VUE_APP_URL
                }/api/v2/bo-notification/list?api_token=${localStorage.getItem(
                    "api_token"
                )}`
            )
            .then((res) => {
              if (res.data.status_code == 400) {
                this.notifications = res.data.data;
              } else {
                this.notifications = res.data.data;
              }
            });
      },
      userSearch() {
        this.$root.$emit("search_text", this.search_text);
        const path = `/user/filter?search_text=${this.search_text}`;
  
        if (this.$route.path !== path) this.$router.push(path);
        // this.$root.$emit('router', this.search_text);
        else
          this.$router.push({
            path: "/user/filter",
            query: {search_text: this.search_text},
          });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  #kt_subheader {
    background-color: #fff;
  
    .subheader-title {
      color: #002147;
      font-weight: 600;
    }
  
    .nav-items {
      list-style-type: none;
      margin-left: 20px;
      margin-bottom: 0;
  
      li {
        display: inline-block;
        padding: 6px 18px;
        font-size: 15px;
        font-family: Arial;
        color: #606060;
  
        a {
          color: inherit;
  
          svg {
            height: 30px;
          }
  
          i {
            color: #606060;
          }
  
          span {
            top: 2px;
            position: relative;
            padding-left: 5px;
          }
        }
  
  
        &.active {
          background-color: #00579b;
          color: #fff;
          border-radius: 20px;
  
  
          i {
            color: #fff;
          }
  
          .icon {
            fill: #fff;
          }
        }
  
        
      }
    }
  }
  .subheader-search-box form {

          input {
            background-color: #d6d6d6;
            color: #fff;
            font-weight: 600;
            text-align: left;
            padding-left: 40px;
            border-radius: 20px;
            outline: none;
            width: 200px;
            &::placeholder {
              color: #fff;
              font-weight: 600;
            }
          }
          
        
  }
  </style>
  <style scoped>
  .dropdown-menu {
    left: -62px !important;
  }
  
  .notification {
    background: red;
    padding: 3px 8px;
    border-radius: 7px;
    position: relative;
    top: -13px;
    left: -6px;
    font-weight: 500;
  }
  
  .custom-notification-div {
    width: 200px !important;
    border-bottom: 1px solid #dddddd;
    /*overflow: hidden;*/
  }
  
  .custom-notification-div .dropdown-item {
    white-space: inherit !important;
  }
  
  .work-speacing {
    white-space: normal !important;
  }
  
  .custom-dropdown button {
    border-radius: 61px !important;
    padding: 9px 6px !important;
    font-weight: bold;
  }
  
  .custom-dropdown-item {
    top: 16px;
    left: -44px;
  }
  
  .dropdown-toggle {
    border-radius: 70px;
  }
  
  /deep/ .btn.btn-secondary {
    background: #fcd603;
    font-size: 25px;
    font-weight: bold;
    padding: 2px 9px;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    overflow: hidden;
  }
  
  .border-redius-0 {
    border-radius: 0px;
  }
  
  .input-icons i {
    position: absolute;
    line-height: 1.3;
  }
  
  .input-icons {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .icon {
    padding: 8px 6px;
    color: #fff;
    min-width: 46px;
    text-align: center;
    font-size: 22px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  
  .sub-header-icon {
    height: 25px;
  }
  </style>
  